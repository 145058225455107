import { Viewer } from '@react-pdf-viewer/core';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  Routes,
  Navigate,
} from 'react-router-dom';
import Pdf from './Pdf';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path='Life-Interior-Design-Standard-Terms.pdf'
            element={<Pdf />}
          />
          <Route
            index
            path='/'
            element={
              <Navigate replace to='Life-Interior-Design-Standard-Terms.pdf' />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
