import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Pdf = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.8.335 /build/pdf.worker.min.js'>
        <Viewer
          fileUrl={'./file.pdf'}
          plugins={[defaultLayoutPluginInstance]}
        ></Viewer>
      </Worker>
    </div>
  );
};

export default Pdf;
